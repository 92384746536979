
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.checkout-container {
  padding: 0 15px;
  margin: 0 auto;
  @include breakpoint('xlarge') {
    max-width: 1270px;
    padding: 0 12px;
  }
  .left-section {
    @include breakpoint('large') {
      padding: 0 15px 0 30px;
    }
    @include breakpoint('xlarge') {
      padding: 0 45px 0 60px;
    }
  }
  .right-section {
    @include breakpoint('large') {
      min-height: 100vh;
      height: 100%;
      padding: 60px;
    }
  }
}
