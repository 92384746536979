
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.product-page-small-title {
  line-height: 23px;
  font-weight: typography.$bold;
  color: color.$black-color;
  display: block;
}

.product-page-description {
  font-weight: typography.$light;
  color: color.$black-color;
  margin: 0 0 5px;
  font-size: 14px;
  line-height: 23px;
  a {
    color: color.$primary-color;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    margin-bottom: 15px;
  }
  p:last-child {
    margin-bottom: 5px;
  }
}
.product-recommended-age {
  margin-bottom: 15px;
}
