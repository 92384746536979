
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.top-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
  text-align: center;
  color: #181818;

  @media (min-width: 768px) {
    padding: 72px 0px;
  }

  .top-row-1 {
    width: 290px;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.48px;

    @media (min-width: 768px) {
      width: auto;
      font-size: 28px;
      line-height: 30px;
      letter-spacing: -0.42px;
    }
  }

  .top-row-2 {
    width: 190px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24px;
    margin: 10px 0px;

    @media (min-width: 768px) {
      width: auto;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.27px;
      margin: 25px 0px;
    }
  }
}

.icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  font-size: 18px;
  text-align: center;

  @media (min-width: 768px) {
    gap: 97px;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none !important;
    color: #181818;
    margin-left: 5px;
    gap: 15px;
    cursor: pointer;

    &:hover {
      color: #181818;
    }

    i {
      font-style: normal;
      font-size: 52px;
      line-height: 1;
      color: #181818;

      &.icn-home-solid {
        &::after {
          content: '\e97b';
        }
      }
      &.icn-go-back {
        &::after {
          content: '\e97a';
        }
      }
      &.icn-refresh {
        &::after {
          content: '\e97c';
        }
      }
    }

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.36px;
    }
  }
}

.chiclets-container {
  padding: 10px 0px;

  @media (min-width: 768px) {
    padding: 0px 0px 30px;
  }

  .section-title {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.33px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .chiclets {
    @media (min-width: 992px) {
      padding: 0px 6px;
    }

    [class*='chiclet-'] {
      color: white;
      font-weight: bold;
      font-size: 17px;
      line-height: 19px;
      padding: 0px 27px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 14px;
        padding: 0px;
      }

      @media (min-width: 992px) {
        font-size: 17px;
        line-height: 19px;
        padding: 0px 15px;
        height: 66px;
      }

      &.chiclet-1 {
        background: #068452;
      }

      &.chiclet-2 {
        background: #c31edc;
      }

      &.chiclet-3 {
        background: #d64000;
      }
      &.chiclet-4 {
        background: #03758f;
      }

      &.chiclet-5 {
        background: #4745ab;
      }

      &.chiclet-6 {
        background: #e60a57;
      }
    }
  }
}

.vertical-align {
  top: 0 !important;
}
