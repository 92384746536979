
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.footer-wrap {
  padding: 15px 0;
  @include breakpoint('medium') {
    padding: 30px 0 15px;
  }
}
.checkout-footer-copyright {
  padding: 15px 0;
  border-top: 1px solid color.$gray5;
}
