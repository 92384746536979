
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.product-resultList-container {
  display: flex;
  flex-direction: column;
  color: color.$black-color;
  .non-product-results {
    margin-top: -30px;
    @include breakpoint('medium') {
      margin-top: 0;
    }
  }
}

.product-resultList-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35px;
  align-items: center;
  &.sku-footer-srp-tabs {
    padding-bottom: 30px;
    @include breakpoint('medium') {
      padding-bottom: 0;
    }
  }
  @include breakpoint('medium') {
    flex-direction: row;
    align-items: normal;
    margin-top: 0;
  }
}

.product-card-handler {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  @include breakpoint('large') {
    margin-bottom: 25px;
    position: static;
    width: auto;
  }
  .filter-text {
    position: relative;
    &::after {
      content: 'Sort by:';
      font-weight: typography.$light;
      font-size: 14px;
      @include breakpoint('large') {
        content: 'SORT:';
        font-weight: typography.$bold;
        font-size: 13px;
      }
    }
  }
  .avaialbility-bottom {
    margin-bottom: 50px;
    @include breakpoint('large') {
      margin-bottom: 0;
    }
  }
  .sort-handler,
  .item-handler {
    position: relative;
    color: color.$primary-color;
    @include breakpoint('large') {
      color: color.$black-color;
    }
    > span {
      font-size: 13px;
      font-weight: typography.$bold;
      display: inline-block;
      margin-right: 0px;
      @include breakpoint('large') {
        margin-right: 8px;
      }
    }
    select {
      background: none;
      border-radius: 4px;
      height: 34px;
      width: 120px;
      -webkit-appearance: none;
      text-align: left;
      padding: 0 18px 0 8px;
      position: relative;
      z-index: 1;
      font-weight: typography.$light;
      color: color.$primary-color;
      border: none;
      margin: 0;
      option {
        font-weight: typography.$light;
      }
      &:focus {
        border-color: #66afe9;
        outline: 0;
        @include breakpoint('medium') {
          -webkit-box-shadow:
            inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 8px rgba(102, 175, 233, 0.6);
          box-shadow:
            inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 8px rgba(102, 175, 233, 0.6);
        }
      }
      @include breakpoint('large') {
        color: color.$black-color;
        border: 1px solid color.$light-gray;
      }
    }
  }
  .sort-handler {
    display: block;
  }
  .item-handler {
    display: none;
    @include breakpoint('large') {
      display: block;
    }
  }
}

.card {
  display: flex;
  flex-direction: row;
  padding: 25px 0;
  border-bottom: 1px solid color.$light-gray;
  @include breakpoint('medium') {
    padding: 0 0 35px;
    border-bottom: 0;
  }
}

.image {
  width: auto;
  height: auto;
  aspect-ratio: 1.333/1;
  object-fit: contain;
  object-position: top;
  @include breakpoint('medium') {
    margin: 0 0 10px;
    width: 100%;
    max-width: 100%;
  }
}

.gift-image {
  aspect-ratio: initial;
}

.details {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.label {
  font-size: 14px;
  text-align: left;
  font-weight: typography.$bold;
  line-height: 18px;
  letter-spacing: -0.015em;
}

.label a {
  color: color.$black-color;
  &:hover {
    text-decoration: underline;
  }
}

.price {
  font-size: 14px;
  color: color.$black-color;
  text-align: left;
}

.store-pickup {
  font-size: 11px;
  margin-bottom: 10px;
  @include breakpoint('medium') {
    margin: 0;
  }
  strong {
    font-weight: typography.$bold;
  }
}

.rating {
  display: flex;
  margin-bottom: 2px;
  a {
    color: color.$primary-color;
    align-items: center;
    display: flex;
    &:hover {
      text-decoration: underline;
    }
  }
}

.fullStar {
  background: variables.$background-lakeshore-sprites -300px -442px / 1000px
    1000px;
  font-size: 18px;
  display: inline-block;
  width: 18px;
  height: 18px;
}

.halfStar {
  background: variables.$background-lakeshore-sprites -300px -442px/1000px
    1000px;
  height: 18px;
  display: inline-block;
  width: 18px;
}

.emptyStar {
  background: variables.$background-lakeshore-sprites -400px -442px / 1000px
    1000px;
  height: 18px;
  display: inline-block;
  width: 18px;
}

.views {
  position: absolute;
  top: 10px;
  right: 0px;
  display: none;
  @include breakpoint('large') {
    display: block;
  }
  .viewsBtn {
    background: transparent;
    border: 0;
    padding: 0;
    font-size: 15px;
    line-height: 1;
    color: color.$black3;
  }
  .inactive-view {
    color: color.$light-gray;
  }
  .icn-grid {
    font-style: normal;
    &::before {
      content: '\e965';
    }
  }
  .icn-list {
    font-style: normal;
    &::before {
      content: '\e968';
    }
  }
  .gridList {
    border-left: 1px solid color.$light-gray;
    margin-left: 10px;
    padding-left: 10px;
  }
}

.gridView {
  &:first-child {
    > div {
      border-top: 1px solid color.$light-gray;
    }
  }
  @include breakpoint('medium') {
    padding: 0 15px;
    &:first-child {
      > div {
        border-top: none;
      }
    }
  }
}

.gridView .card {
  flex-direction: row;
  @include breakpoint('medium') {
    flex-direction: column;
  }
}

.product-resultList-footer-button {
  position: relative;
  &::after {
    content: 'Back to Top';
    @include breakpoint('medium') {
      content: 'Top';
    }
  }
}

.reg {
  display: block;
  font-size: 12px;
  color: color.$blue-black;
  line-height: 20px;
}

.sale {
  color: color.$red;
}

.gsaUser {
  color: color.$green;
}

.isNew-store-wrap {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  .isNew {
    span {
      background-color: color.$tangerine2;
      display: inline-block;
      margin-right: 6px;
      padding: 3px 6px;
      text-transform: uppercase;
      font-size: 75%;
      font-weight: typography.$bold;
      line-height: 1;
      color: color.$white-color;
      text-align: center;
      border-radius: 0.25em;
    }
  }
  .is-store-only-product {
    span {
      font-size: 12px;
      color: color.$primary-color;
    }
  }
}

.back-to-top-footer {
  margin-top: 15px;
  color: color.$primary-color;
  border: 2px solid color.$primary-color;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: typography.$medium;
  padding: 7px 32px;
  font-size: 16px;
  line-height: 27px;
  text-transform: capitalize;
  @include breakpoint('medium') {
    border: none;
    width: auto;
    margin-top: 0;
    padding: 0;
    padding: 0;
    font-size: initial;
  }
}

.back-to-top {
  color: color.$primary-color;
  cursor: pointer;
  order: 1;
  border-radius: 4px;
  @include breakpoint('medium') {
    order: 0;
  }
  span {
    font-size: 14px;
    line-height: 22px;
    @include breakpoint('medium') {
      margin-top: 5px;
      display: block;
      order: 1;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .icn-back-to-top {
    font-style: normal;
    font-size: 29px;
    display: none;
    @include breakpoint('medium') {
      display: block;
    }
    &::before {
      content: '\e903';
    }
  }
}

.caret-select {
  margin-left: -19px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  position: absolute;
  top: 15px;
  display: inline-block;
  @include breakpoint('large') {
    display: none;
  }
}

.select-option {
  display: inline-block;
  button {
    min-width: 86px;
  }
  @media screen and (max-width: 991px) {
    button.select-opt-button {
      font-size: 14px;
      line-height: 27px;
      background-color: transparent;
      border: 0;
      padding: 0 0 0 3px;
      color: color.$primary-color;
      &:hover {
        background-color: transparent;
        color: color.$primary-color;
      }
      &:focus {
        box-shadow: none;
      }
      span {
        display: none;
      }
    }
  }
}

#back-to-top a {
  ::after {
    content: 'TOP';
  }
}

.image-wrapper {
  width: 41%;
  @include breakpoint('medium') {
    width: 100%;
  }
}

.image-wrapper,
.details {
  padding: 0 15px;
  @include breakpoint('medium') {
    padding: 0;
  }
}

.listView {
  margin-bottom: 25px;
  font-weight: typography.$light;
  .long-sescription,
  .age-grade-range,
  .repository-id,
  .rating,
  .price {
    margin-bottom: 10px;
    font-weight: typography.$light;
  }
  .label {
    margin-bottom: 0;
  }
  .price {
    font-weight: typography.$light;
  }
  .image-wrapper {
    width: 133px;
    height: 133px;
    @media screen and (min-width: 371px) {
      width: 160px;
      height: 160px;
    }
    @media screen and (min-width: 499px) {
      width: 220px;
      height: 220px;
    }
    @include breakpoint('medium') {
      width: 240px;
      height: 240px;
    }
    @include breakpoint('large') {
      width: 264px;
      height: 264px;
    }
    @include breakpoint('xlarge') {
      margin-right: 20px;
    }
  }
  .card {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .details {
    width: calc(100% - 133px);
    padding: 0 15px;
    @include breakpoint('large') {
      width: calc(100% - 300px);
    }
    @include breakpoint('medium') {
      width: calc(100% - 260px);
    }
    @media screen and (min-width: 499px) {
      width: calc(100% - 240px);
    }
    @media screen and (min-width: 371px) {
      width: calc(100% - 160px);
    }
  }
}

.top-side-pagination {
  display: none;
}

@include breakpoint('large') {
  .top-side-pagination {
    display: block;
    padding-right: 0;
  }
}

.filter {
  display: flex;
  @include breakpoint('medium') {
    display: none;
  }
}

.active .nav-mobile {
  display: block;
}

.divide {
  background: color.$gray;
  height: 2px;
  width: 100%;
  padding: none;
}

.product-resultList-container {
  .giftCardHeader {
    font-weight: typography.$bold;
    font-size: 1.125rem;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    padding-top: 15px;
    &::after {
      content: '';
      top: 0;
      display: flex;
      justify-content: center;
      width: 95%;
      height: 1px;
      position: absolute;
      background: color.$light-gray;
    }
    @include breakpoint('medium') {
      padding-top: 0;
      &:after {
        content: none;
      }
    }
  }
}

.handler-dropdown {
  font-weight: typography.$light;
}

.store-pricing {
  font-size: 11px;
  color: color.$red;
}

@-moz-document url-prefix() {
  .sort-handler,
  .item-handler {
    select {
      option {
        font-family: sans-serif;
      }
    }
  }
}

.preSellDate {
  font-size: 11px;
}
.isLLExclusive {
  color: color.$green2;
  font-size: 12px;
  font-weight: typography.$medium;
}

.product-card-wrapper {
  .card {
    gap: 15px;
    @include breakpoint('medium') {
      gap: 0;
    }
  }
  .rating {
    flex-wrap: wrap;
    a {
      flex-wrap: wrap;
    }
  }
  .image-wrapper {
    width: 165px;
    @media screen and (min-width: 376px) {
      width: 48.65%;
    }
    @include breakpoint('small') {
      width: 50%;
    }
    @include breakpoint('medium') {
      width: 100%;
    }
  }
  .image-wrapper,
  .details {
    padding: 0;
  }
}
