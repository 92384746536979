
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.header-wrap {
  margin: 15px 0;
  @include breakpoint('medium') {
    margin: 30px 0 30px;
  }
  @include breakpoint('large') {
    margin: 33px 0 30px;
  }
  @include breakpoint('xlarge') {
    margin: 41px 0 30px;
  }
}
